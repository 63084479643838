import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
import "./scss/main.scss";

import HomePage from "./components/pages/HomePage";
import NotFoundPage from "./components/pages/NotFoundPage";
import TeamPage from "./components/pages/TeamPage";
import ContactUsPage from "./components/pages/ContactUsPage";
import CalendarPage from "./components/pages/CalendarPage";
import ComingSoonPage from "./components/pages/ComingSoonPage";
import FestivalsPage from "./components/pages/festivals/FestivalsPage";
import FestivalPage from "./components/pages/festivals/FestivalPage";
import GalleryPage from "./components/pages/gallery/GalleryPage";
import EducationPage from "./components/pages/education/EducationPage";
import NewsPage from "./components/pages/news/NewsPage";
import EventsPage from "./components/pages/events/EventsPage";
import VisionPage from "./components/pages/VisionPage";
import MissionPage from "./components/pages/MissionPage";
import { Miscellaneous } from "./components/pages/publications/Miscellaneous";
import ECardsPage from "./components/pages/publications/ECardsPage";

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<HomePage />} />

				<Route path="/about-us" element={<ComingSoonPage />} />

				<Route path="/our-vision" element={<VisionPage />} />

				<Route path="/our-mission" element={<MissionPage />} />

				<Route path="/our-policy" element={<ComingSoonPage />} />

				<Route path="/team" element={<TeamPage />} />

				<Route path="/calendar" element={<CalendarPage />} />

				<Route path="/events" element={<EventsPage />} />

				<Route path="/gallery" element={<GalleryPage />} />

				<Route path="/contact-us" element={<ContactUsPage />} />

				<Route path="/coming-soon" element={<ComingSoonPage />} />

				<Route path="/feedback" element={<ComingSoonPage />} />

				<Route path="/festivals" element={<FestivalsPage />} />

				<Route path="/festival/:id" element={<FestivalPage />} />

				<Route path="/education" element={<EducationPage />} />

				<Route path="/news" element={<NewsPage />} />

				<Route path="/publications/ecards" element={<ECardsPage />} />

				<Route
					path="/publications/miscellaneous"
					element={<Miscellaneous />}
				/>

				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		</Router>
	);
}

export default App;
