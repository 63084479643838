// we will have unique ecards
// and another array with userID and ecardID
// id will be string and will be unique

// list of ecards
export const ecards = [
	{
		id: "046d52d5-5fe5-490b-8652-040d9a367357",
		title: "Happy Chasok Tangnam",
		description: "Version 1",
		thumbnail:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/chasok-tangnam%2Fcard1-thumbnail.png?alt=media&token=a2c58eec-2f48-4c47-9820-65bf94e20125",
		imageUrl: "https://placehold.co/300x200",
	},
	{
		id: "297d77de-be76-4015-a92b-01a36be9df1a",
		title: "Happy Chasok Tangnam",
		description: "Version 2",
		thumbnail:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/chasok-tangnam%2Fcard2-thumbnail.png?alt=media&token=b73b6590-1aa7-4727-bd2f-44d2ea7b10dd",
		imageUrl: "https://placehold.co/300x200",
	},
	{
		id: "7c1a7bfc-0e3f-46a3-9828-c7ef83f9acb7",
		title: "Happy Chasok Tangnam",
		description: "Version 3",
		thumbnail:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/chasok-tangnam%2Fcard3-thumbnail.png?alt=media&token=19ba3b65-f4d1-4d4f-a8fd-8294cf13b534",
		imageUrl: "https://placehold.co/300x200",
	},
	{
		id: "ad5c9ec1-c64c-400a-ba7f-dd7d8719462c",
		title: "Happy Chasok Tangnam",
		description: "Version 4",
		thumbnail:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/chasok-tangnam%2Fcard4-thumbnail.png?alt=media&token=5ab14015-5958-43d4-bda8-f880e7b34ae9",
		imageUrl: "https://placehold.co/300x200",
	},
];

// list of userEcards
export const userEcards = [
	{
		userID: "1",
		ecardID: "046d52d5-5fe5-490b-8652-040d9a367357",
	},
	{
		userID: "1",
		ecardID: "297d77de-be76-4015-a92b-01a36be9df1a",
	},
	{
		userID: "2",
		ecardID: "7c1a7bfc-0e3f-46a3-9828-c7ef83f9acb7",
	},
	{
		userID: "3",
		ecardID: "ad5c9ec1-c64c-400a-ba7f-dd7d8719462c",
	},
];
