// shows list of ecards as flex items

const ECardList = ({ items }) => {
	const render = items.map((item) => (
		<ECardListItem key={item.id} item={item} />
	));

	return <div className="flex flex-col">{render}</div>;
};

// single ecard item
const ECardListItem = ({ item }) => {
	return (
		<div className="mb-4 shadow rounded-lg p-4 cursor-pointer bg-gray-100 hover:bg-gray-200">
			<h1 className="font-bold mb-2">{item.title}</h1>
			<img src={item.thumbnail} alt={item.title} />
			<p className="pt-2 text-slate-600 text-sm">{item.description}</p>
		</div>
	);
};

export default ECardList;
