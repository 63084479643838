// page for showing list of ecards
// we will show individual card in modal

import PageTitle from "../../common/PageTitle";
import ECardList from "../../features/ecard/ECardList";
import PageLayout from "../../layout/PageLayout";

import { ecards } from "../../../data/ecards";

const ECardsPage = () => {
	const content = (
		<div>
			<PageTitle title="Ecards" />

			<ECardList items={ecards} />
		</div>
	);

	return <PageLayout children={content} />;
};

export default ECardsPage;
